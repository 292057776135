.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

.card-tools {
  float: right;
  /* margin-right: -0.625rem; */
}

.not_ready_trans {
  background: #ff6464;
}
.list-icons {
  font-size: 25px;
}

.react-datetime-picker__wrapper,
.emptyFilter {
  height: 30px;
}

.d-flex,
.info-box,
.info-box .info-box-icon {
  display: -ms-flexbox !important;
  display: flex !important;
}

.justify-content-center,
.info-box .info-box-icon {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.align-items-center,
.info-box .info-box-icon {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.info-box .info-box-icon > img {
  max-width: 100%;
}

.info-box .info-box-icon > img {
  max-width: 100%;
}

.info-box .info-box-icon {
  border-radius: 0.25rem;
  display: block;
  font-size: 1.875rem;
  text-align: center;
  width: 70px;
}

.info-box .info-box-icon > img {
  max-width: 100%;
}

.info-box .info-box-content {
  -ms-flex: 1;
  flex: 1;
  padding: 5px 10px;
}

.info-box .progress-description,
.info-box .info-box-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-box {
  margin-top: 1rem;
  min-height: 150px;
}

.info-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  background: #ffffff;
  min-height: 80px;
  padding: 0.5rem;
  position: relative;
}
